@import '../../styles/variables';


.main-container {
 
  .ftco-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 7em 0; }
    
    .wrap {
      width: 100%;
      border-radius: 5px;
      -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
      box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24); }


  .mb-4 {
    margin-bottom: 1.5rem !important; 
  }
    
  .w-100 {
    width: 100% !important; 
  }
  
  .p-4 {
    padding: 1.5rem !important; 
  }
  
  .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; 
  }
  
  .login-wrap {
    position: relative;
    background: #fff;
  }
    .login-wrap h3 {
      font-weight: 300; 
  }
  .text-wrap {
    white-space: normal !important; 
  }
  .text-wrap, .login-wrap {
    width: 50%; 
  }
  
  .text-center {
    text-align: center !important; 
   }
  
  .text-wrap {

      //   // background: -webkit-linear-gradient(to right, #2a3042, #3d445a);
  //   // background: linear-gradient(to right, #2a3042, #3d445a);
    background: $sidebar-bg;
    background: -moz-linear-gradient(-45deg, $sidebar-bg 0%, $sidebar-bg-blend 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, $sidebar-bg), color-stop(100%, $sidebar-bg-blend));
    background: -webkit-linear-gradient(-45deg, $sidebar-bg 0%, $sidebar-bg-blend 100%);
    background: -o-linear-gradient(-45deg, $sidebar-bg 0%, $sidebar-bg-blend 100%);
    background: -ms-linear-gradient(-45deg, $sidebar-bg 0%, $sidebar-bg-blend 100%);
    background: -webkit-linear-gradient(315deg, $sidebar-bg 0%, $sidebar-bg-blend 100%);
    background: -o-linear-gradient(315deg, $sidebar-bg 0%, $sidebar-bg-blend 100%);
    background: linear-gradient(135deg, $sidebar-bg 0%, $sidebar-bg-blend 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$sidebar-bg', endColorstr='$sidebar-bg-blend', GradientType=1 );
    color: #fff; }
    .text-wrap .text h2 {
      font-weight: 900;
      color: #fff;
  }
  
  
  .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; 
  }
  
  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; 
  }

  .show-custom-error{
    color: $red;
  }
  
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 576px) {
      .container {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .container {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .container {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .container {
        max-width: 1140px; 
    }
   
  }
  
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
    
  .col-md-12, .col-lg-10{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    
    .order-md-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; 
    }
    
    .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
  }
  
  
  @media (max-width: 991.98px) {
      .text-wrap, .login-wrap {
        width: 100%; 
    } 
  
  }
  
  @media (min-width: 992px) {
    .p-lg-5 {
      padding: 3rem !important; 
    }
    
    .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%; 
    }
  }
  
  













  // @media (min-width: 1200px){
  //   height: 100vh;
  // }
  
  // .login-side {
  //   background: #2a3042;
  //   // background: -webkit-linear-gradient(to right, #2a3042, #3d445a);
  //   // background: linear-gradient(to right, #2a3042, #3d445a);

  //   // background: radial-gradient(circle at top, #2a3042, #3d445a),    radial-gradient(circle at bottom, #3d445a, #3d445a);

  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: 0 0;
  //   display: flex;

  //   .bg-overlay {
  //     height: 100%;
  //     .panelTop {
  //         height: 20%;
  //     }

  //     .panelCenter {
  //       height: 60%;
  //       h1 {
  //         color: #fff;
  //       }
  //     }

  //     .panelBottom {
  //       height: 20%;
  //     }

  //   }

    
  //   @media (min-width: 1200px){
  //     height: 100vh;
  //   }

  //   &::before{
  //     content: "";
  //     position: absolute;
  //     width: 300px;
  //     height: 300px;
  //     border-radius: 50%;
  // }
  // .bg-image {
  //   object-fit: cover;
  //   width: 100%;
  // height: 100%;
  // }

  // }
  
  // .login-content {

  //   .app-logo {
  //     display: $display-block;
  //   }

  //   @media (min-width: 1200px){
  //     height: 100vh;
  //   }
  //   background: #fff;
  //   @media (min-width: 1200px){
  //       min-height: 100vh;
  //   }
  //   .form-container {
  //     margin-top: auto !important;
  //     margin-bottom: auto !important;

  //     .show-custom-error{
  //       color: $red;
  //     }
  //   }

  
  // }


}


