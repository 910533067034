.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: fixed;
    right: 0;
    color: $footer-color;
    left: 250px;
    height: $footer-height;
    background-color: $footer-bg;

    .copyright{
        @include font(2, light);
    }

    @media (max-width: 991.98px) {
        left: 0;
    }
}
.side-menu-collpsed {
    .footer {
        left: $sidebar-collapsed-width;

        @media (max-width: 991.98px) {
            left: 0;
        }
    }
}


