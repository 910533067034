// App Colors

$light-blue: #2f92d0;
$dark-blue: #085488;
$background: #fafafa;
$grey-background: #f7f6f6;
$border: #d3dbdf;
$blue:    #2a1abb;
$red:     #dd6363;
$black: #000000;
$orange:  #ee7858;
$yellow:  #e7af4e;
$green:   #33d499;
$white: #ffffff;
$grey: #919191;
$yellow: #d9dd14ec;
$gray-100: #212529;
$gray-200: #2a3042;
$gray-300: #32394e;
$gray-400: #a6b0cf;
$gray-500: #bfc8e2;
$gray-600: #c3cbe4;
$gray-700: #f6f6f6;
$gray-800: #eff2f7;
$gray-900: #f8f9fa;
$gray-disabled : #d4d4d4;


// Display statuses
$display-none: block;
$display-block: none;


//Table
$table-heading: #ebeef3;
$table-border: #dadada;

//bootstrap overrides
$primary:#0d9488;
$secondary:#c6cecd;
$info:$light-blue;
$danger: #e24545;
$success: #01bf9b;
$warning: #ffc107;


$secondarssy:#8fc7c2;

//
$text-heading: #1E293B;
$text-color: #3c3c3f;
$input-border:#e3e6e5;
$alert: $yellow;
$error: $danger;


// All about the layout
$body-bg:#f6f6f6;
$body-color:$gray-400;
$body-text-align:null;

$boxed-layout-width:1300px;
$boxed-body-bg: #32394f;





//Side bar
$sidebar-bg: #2a3042;
$sidebar-bg-blend: #3d445a;
$sidebar-text-color: $gray-400;
$sidebar-hover: #3d445a;
$sidebar-hover-border: $danger;

$sidebar-menu-item-color: #a6b0cf;
$sidebar-menu-sub-item-color: #79829c;
$sidebar-menu-item-icon-color: #6a7187;
$sidebar-menu-item-hover-color: #ffffff;
$sidebar-menu-item-active-color: #ffffff;
$sidebar-width:  250px;
$sidebar-collapsed-width:  0px;
$sidebar-width-sm:  160px;
$layout-collapsed-min-height: 1760px;


$header-height: 70px;
$header-bg: #ffffff;
$header-item-color: #555b6d;



// Brand 
$navbar-company-box-width: 250px;



// Font Weight
$fw-medium: 500;
$font-weight-semibold: 600;

// Footer
$footer-height: 60px;
$footer-bg: #fafafa;
$footer-color: #a6b0cf;

$gray-600: #74788d;
$card-title-desc: $gray-600;