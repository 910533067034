@import '../variables';
@import '../fonts';


.card-columns {
    .card {
      margin-bottom: $grid-gutter-width;
    }
  
    @include media-breakpoint-up(sm) {
      column-count: 3;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
  
      .card {
        display: inline-block; // Don't let them vertically span multiple columns
        width: 100%; // Don't let their width change
      }
    }
  }

  .card {
    margin-bottom: $grid-gutter-width;
    padding-bottom: 10px;
    //box-shadow: $box-shadow;
  }
  
  .card-drop {
    color: $text-color;
  }
  .card-body{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: $font-weight-semibold;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(77,82,89,0.07);
  }
  .card-subtitle {
    @include font(1, 'bold');
    padding: 5px 20px 3px 10px;
    border-bottom: 1px solid rgba(77,82,89,0.07);
  }
  
  .card-title-desc {
    color: $card-title-desc;
    margin-bottom: 24px;  
  }
  