@import '../../variables';

@media print {
    .vertical-menu,
    .side-menu,
    .page-title-box,
    .navbar-header,
    .footer {
        display: none !important;
    }
    .card-body,
    .main-content,
    .side-menu,
    .page-content,
    body {
        padding: 0;
        margin: 0;
    }

    .card{
        border: 0;
    }

    

    @page {
        size: A4;
        margin: 2cm;
        border-bottom: 1px solid rgb(218, 218, 218) ;
      
  
        @bottom-center {
          font-size: 10pt;
          content: "Page " counter(page) " of " counter(pages);
          text-align: right;
        }
      }
}

.report-title{
    padding-bottom: 1.5rem !important;
    border-bottom: solid 1px $table-border;
    margin-bottom: 1.5rem !important;
}

.reportLine {
    border-bottom: solid 1px $table-border;
}

