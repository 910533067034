@import '../variables';
@import '../fonts';

.table {
    width: 100%;
    


    tr {
      th {
        @include font(1, 'bold');
        padding: 10px 8px;
        text-align: left;
        background-color:$table-heading;
        border: none;
        color: $text-color;
      }
  
      td {
        color: $text-color;
        @include font(1);
        padding: 8px 10px;
        border: none;
        border-bottom: $table-border solid 1px;
      }
    }
  }

.table > tbody {
    vertical-align: middle;
}

.tablelight {
  width: 100%;

  tr {
    th {
      @include font(1, 'bold');
      padding: 10px 8px;
      text-align: left;
      background-color:$white;
      color: $text-color;
      border-bottom: $table-border solid 1px !important;

      &:last-child {
        text-align: right;
        padding-right: 30px;
      }
    }

    td {
      color: $text-color;
      @include font(1);
      border: none;
      border-bottom: $table-border solid 1px;

      &:last-child {
        text-align: right;
        padding-right: 30px;
      }

    }
    &:nth-child(even) {
      background: $background;
    }

    &:nth-child(odd) {
      background: $white;
    }
    .summary tr td{
      font-weight: bold;
    }
  }
}

.tablelight > tbody {
  vertical-align: middle;
}