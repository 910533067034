#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: $sidebar-width;
  z-index: 1002;
  background-color: $header-bg;
  box-shadow: $box-shadow;
  @media (max-width: 991.98px) {
    left: 0;
  }

  .dropdown-userinfo {
    left: auto !important;
    right: 0 !important;
    background-color: $gray-900;
  }

  .dropdown-userinfo {
    left: auto !important;
    right: 0 !important;
    background-color: $gray-900;

    .dropdown-item {
      background-color: $gray-900;
      color: $text-color;

      &:hover {
        background-color: $gray-700;
      }
    }
  }
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;
}

.navbar-company-box {
  padding: 0 1.5rem;
  text-align: center;
  width: $navbar-company-box-width;
}

.logo {
  line-height: 70px;

  .logo-sm {
    display: none;
  }
}

.logo-light {
  display: none;
}

@media (max-width: 992px) {
  .navbar-company-box {
    width: auto;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.page-content {
  padding: calc(#{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) 165px calc(#{$grid-gutter-width} / 2);
}

.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;
  position: relative;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-300;
  padding: 3px;
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .navbar-company-box {
    display: none;
  }
}

@media (max-width: 992px) {
}
