@import '../../styles/variables';


.noRoute {
  padding: 36px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: $white;
  margin: auto;
  text-align: center;

  h1 {
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    color: $light-blue;
  }
}
