

@font-face {
    font-family: "NotoSans";
    src: local("NotoSans"), url("../assets/fonts/NotoSansKR-Thin.otf") format("opentype");
    font-weight: 100;
}
@font-face {
    font-family: "NotoSans";
    src: local("NotoSans"), url("../assets/fonts/NotoSansKR-Light.otf") format("opentype");
    font-weight: 300;
}
@font-face {
    font-family: "NotoSans";
    src: local("NotoSans"), url("../assets/fonts/NotoSansKR-Regular.otf") format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: "NotoSans";
    src: local("NotoSans"), url("../assets/fonts/NotoSansKR-Medium.otf") format("opentype");
    font-weight: 500;
}
@font-face {
    font-family: "NotoSans";
    src: local("NotoSans"), url("../assets/fonts/NotoSansKR-Bold.otf") format("opentype");
    font-weight: 700;
}
@font-face {
    font-family: "NotoSans";
    src: local("NotoSans"), url("../assets/fonts/NotoSansKR-Black.otf") format("opentype");
    font-weight: 900;
}

$main-family : 'NotoSans', sans-serif;
$fallback-family : Helvetica, Arial, sans-serif;


$font-scale: (
  (0.75rem, 1.25rem),
  (0.938rem, 1.5rem),
  (1rem, 1.75rem),
  (1.25rem, 1.75rem),
  (1.5rem, 2rem),
  (1.875rem, 2.5rem),
  (2.25rem, 3rem),
  (2.5rem, 3.25rem),
  (2.75rem, 3.5rem)
) !default;

@mixin font($size, $type: 'regular') {
    $family: $main-family;
    $weight: 400;
  
    @if ($type == 'thin') {
      $weight: 100;
    } @else if ($type == 'light') {
      $weight: 300;
    } @else if ($type == 'medium') {
        $weight: 500;
    } @else if ($type == 'bold') {
      $weight: 700;
    }@else if ($type == 'black') {
        $weight: 900;
    }
  
    font-family: $family, $fallback-family;
    font-size: nth(nth($font-scale, $size), 1);
    line-height: nth(nth($font-scale, $size), 2);
    font-weight: $weight !important;
  }