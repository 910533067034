@import '../../../styles/variables';
@import '../../../styles/fonts';

.tablerow{
    padding-top: 10px;
}

.buttonrow{
    padding-top: 10px;
}

.tableIcons{
    padding: 0px !important;
}

.button-m-top {
    margin-top: 25px;
}
.summary{
    height: 36px;
    background-color: $gray-700!important;
    border-top: 1px solid $black !important;
    border-bottom: 1px solid $black !important;
    td {
        @include font(2, 'bold');
    }

    .summaryLabel{
        padding-right: 10px;
        margin-right: 10px;
        font-weight: bold;
    }
    .summaryValue {
        padding-left: 10px;
        margin-right: 27px;
    }
    .label-padding {
        padding-right: 15px;
    }
}

@page {
    @bottom-left {
      content: counter(page) ' of ' counter(pages);
    }
  }