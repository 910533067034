@import '../../../styles/variables';
@import '../../../styles/fonts';

.pagination {
  width: 100%;

  .showing {
    display: flex;
    -ms-flex-item-align: left;
    margin-top: 20px;
    float: left;
    .info {
      color: $text-color;
      font-size: 15px;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
    }

  }

  .controls {
    display: flex;
    -ms-flex-item-align: right;
    // justify-content: space-between;
    margin-top: 20px;
    @media (min-width: 576px) {
      float: right;
    }
    @media (max-width: 575.99px) {
      justify-content: center;
    }

    .pageLinks {
      display: flex;
    }
    .arrowClass {
      color: $primary;
    }

    button {
      outline: none;
      border-radius: 15px;
      border: 1px solid $white;
      @include font(1);
      margin: 0 5px;
      position: relative;
      height: 32px;
      width: 32px;
      font-size: 15px;

      &.numbers {
        width: 32px;
        background-color: $white;
      }

      &.active {
        border: 1px solid $primary;
        background-color: $primary;
        color: $white !important;
      }

      &:first-child {
        margin-left: 0;
        &[disabled] {
          background-color: $background;
          border: 2px solid $white;
        }
        &:not(.active) {
          background-color: $white;
        }
      }

      &:last-child {
        margin-right: 0;
        &[disabled] {
          background-color: $background;
          border: 1px solid $white;
        }
        &:not(.active) {
          background-color: $white;
        }
      }

      &:hover:not(.active) {
        &:not([disabled]) {
          background-color: $background;
          border: 2px solid $primary;
        }
      }

      &[disabled] {
        cursor: default;
        opacity: 0.5;
      }
    }
  }
}
