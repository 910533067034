
.side-menu {
  width: $sidebar-width;
  z-index: 1001;
  background: $sidebar-bg;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  padding-top: $header-height;
  box-shadow: $box-shadow;
  @media (max-width: 991.98px) {
    top: $header-height;
    padding-top: 0px;
  }

  .navbar-company-box{
    position: fixed;
    top: 0px;
    z-index: 99;
    @media (max-width: 991.98px) {
      display: none;
    }
  }
}

.main-content {
  margin-left: $sidebar-width;
  overflow: hidden;

  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }
}

// overwrites for the side bar
.side-navigation-panel {
  .hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: $sidebar-hover
  }
  
  .hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: $sidebar-hover-border
  }

  .side-navigation-panel-select {
    

    .side-navigation-panel-select-wrap {

      .side-navigation-panel-select-option { 
        color: $sidebar-text-color;
      }
      .side-navigation-panel-select-option-text {
        @include font(2, 'light');
      }


    }
  }

}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option {
	border-left-width: 2px;
	cursor: pointer;
	display: block;
	font-size: .875rem;
	line-height: 1.25rem;
	padding: .5rem 2rem .5rem 3.75rem;
	--tw-text-opacity: 1;
	color: $sidebar-text-color;
}


.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option-selected {
	--tw-bg-opacity: 1;
	background-color: $sidebar-hover;
	--tw-border-opacity: 1;
	border-color: $sidebar-hover-border;
	--tw-text-opacity: 1;
	color: $white
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected {
	--tw-bg-opacity: 1;
	background-color: $sidebar-hover;
	--tw-border-opacity: 1;
	border-color: $sidebar-hover-border;
	--tw-text-opacity: 1;
	color: $white
}




.side-navigation-panel *, .side-navigation-panel:after, .side-navigation-panel:before {
  border-color: $primary;
}

@media (max-width: 992px) {
  .side-menu {
    display: block;
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable {
    .side-menu {
      display: block;
    }
  }
}

// Enlarge menu
.side-menu-collpsed {
  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-company-box {
    width: $sidebar-collapsed-width !important;
  }

  #page-topbar {
    left: $sidebar-collapsed-width;
    @media (max-width: 991.98px) {
        left: 0;
    }
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: block;
    }
  }

  // Side menu
  .side-menu {
    position: absolute;
    width: $sidebar-collapsed-width !important;
    z-index: 5;


    .simplebar-scrollbar {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
      .menu-title,
      .badge,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      .has-arrow {
        &:after {
          display: none;
        }
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            padding: 15px 20px;
            min-height: 55px;
            transition: none;

            &:hover,
            &:active,
            &:focus {
              color: $sidebar-menu-item-hover-color;
            }

            i {
              font-size: 1.45rem;
              margin-left: 4px;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(190px + #{$sidebar-collapsed-width});
              color: $primary;
              background-color: darken($sidebar-bg, 4%);
              transition: none;

              i {
                color: $primary;
              }

              span {
                display: inline;
              }
            }

            > ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 190px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
              }

              a {
                box-shadow: none;
                padding: 8px 20px;
                position: relative;
                width: 190px;
                z-index: 6;
                color: $sidebar-menu-sub-item-color;

                &:hover {
                  color: $sidebar-menu-item-hover-color;
                }
              }
            }
          }
        }

        ul {
          padding: 5px 0;
          z-index: 9999;
          display: none;
          background-color: $sidebar-bg;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $gray-100;
            }
          }
        }
      }
    }
  }
}

// Compact Sidebar

body[data-sidebar-size="small"] {
  .navbar-company-box {
    width: $sidebar-width-sm;
  }
  .side-menu {
    width: $sidebar-width-sm;
    text-align: center;

    .has-arrow:after,
    .badge {
      display: none !important;
    }
  }
  .main-content {
    margin-left: $sidebar-width-sm;
  }
  .footer {
    left: $sidebar-width-sm;
    @media (max-width: 991.98px) {
      left: 0;
    }
  }

  #page-topbar {
      left: $sidebar-width-sm;
  }

  #sidebar-menu {
    ul li {
      a {
        i {
          display: block;
        }
      }
      ul.sub-menu {
        li {
          a {
            padding-left: 1.5rem;
          }

          ul.sub-menu li a {
            padding-left: 1.5rem;
          }
        }
      }
    }
  }
  &.side-menu-collpsed {
    .main-content {
      margin-left: $sidebar-collapsed-width;
    }
    .side-menu {
      #sidebar-menu {
        text-align: left;
        > ul {
          > li {
            > a {
              i {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .footer {
      left: $sidebar-collapsed-width;
    }
  }
}


// colored sidebar

body[data-sidebar="colored"]{

  .navbar-company-box {
    .logo-dark {
      display: none;
    }
    .logo-light {
      display: block;
    }
  }

  .mm-active {
    color: $white !important;
    > a {
      color: $white !important;
      i {
        color: $white !important;
      }
    }
    > i,
    .active {
      color: $white !important;
    }
  }

  #sidebar-menu {
    ul {
      li {
        &.menu-title {
          color: rgba($white, 0.6);
        }

        a {
          color: rgba($white, 0.6);
          i {
            color: rgba($white, 0.6);
          }
          &.waves-effect {
            .waves-ripple {
              background: rgba($white, 0.1);
            }
          }

          &:hover {
            color: $white;

            i {
              color: $white;
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: rgba($white, 0.5);
              &:hover {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  &.side-menu-collpsed {
    .side-menu {
      #sidebar-menu {
        > ul {
          > li {
            &:hover > a {
              color: $white;
              i {
                color: $white;
              }
            }
          }
        }

        ul {
          li {
            &.mm-active {
              .active {
                color: $sidebar-menu-item-active-color !important;
              }
            }

            ul.sub-menu {
              li {
                a {
                  &:hover {
                    color: $sidebar-menu-item-active-color;
                  }
                }
                &.mm-active {
                  color: $sidebar-menu-item-active-color !important;
                  > a {
                    color: $sidebar-menu-item-active-color !important;
                    i {
                      color: $sidebar-menu-item-active-color !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

