@import '../../styles/variables';

.unauthorised {
  display: flex;
  height: 55vh;

  .container {
    padding: 36px;
    text-align: center;
    margin: auto;
    a {
      text-decoration: none;
      color: $dark-blue;
    }
    .subHeading {
      margin-bottom: 30px;
    }
  }
}
